import React, { useState, useEffect, useRef } from "react";
import { functions } from "./Firebase";
import { httpsCallable } from "firebase/functions";

const Locator = () => {
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const scriptRef = useRef(null);

  const initializeMap = async () => {
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    const location = { lat: 32.8674, lng: -96.9606 };
    const googleMap = new Map(mapRef.current, {
      center: location,
      zoom: 17,
      mapId: "9ace6a850a670fde",
    });
    setMap(googleMap);

    const advancedMarker = new AdvancedMarkerElement({
      position: location,
      map: googleMap,
      title: "Reform Ortho & Sports Rehab", 
    });

    const infoWindow = new window.google.maps.InfoWindow({
      content: `<div><strong>Reform Ortho & Sports Rehab</strong><br>4301 N MacArthur Blvd, Irving, TX 75039, USA<br><a href='https://www.google.com/maps/dir/?api=1&destination=32.8674,-96.9606' target='_blank'>Get Directions</a></div>`,
    });

    advancedMarker.addListener("click", () => {
      infoWindow.open(googleMap, advancedMarker);
    });

    infoWindow.open(googleMap, advancedMarker);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      initializeMap();
    } else {
      const getApiKey = httpsCallable(functions, "getGoogleMapsApiKey");
      getApiKey()
        .then((result) => {
          const apiKey = result.data.apiKey;

          if (!scriptRef.current) {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
            scriptRef.current = script;

            script.onload = initializeMap;

            script.onerror = (e) => {
              console.error("Error loading Google Maps script", e);
            };
          }
        })
        .catch((error) => {
          console.error("Error fetching API key:", error);
        });
    }
  }, []);

  return (
    <div id="map-container" style={{ height: "300px", width: "100%" }}>
      <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default Locator;
